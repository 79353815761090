import React, { Component, ErrorInfo, ReactNode } from 'react';

import { MaintenancePage } from '../MaintenancePage/MaintenancePage.tsx';

interface ErrorBoundaryProps {
  children: ReactNode;
  error: boolean;
  displayOriginalOnError?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// @TODO - convert to hook when supported https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    error: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch = (error: Error, info: ErrorInfo) => {
    console.error(error, info);
  };

  render() {
    if (this.state.hasError || this.props.error) {
      if (this.props.displayOriginalOnError) {
        return this.props.children;
      }

      // You can render any custom fallback UI
      return <MaintenancePage />;
    }

    return this.props.children;
  }
}
