import appleTouchIcon from './apple-touch-icon.png';
import favicon32x32 from './favicon-32x32.png';
import favicon194x194 from './favicon-194x194.png';
import androidChrome192x192 from './android-chrome-192x192.png';
import favicon16x16 from './favicon-16x16.png';
import webmanifest from './site.webmanifest';
import safariPinnedTab from './safari-pinned-tab.svg';

const favicon = [
  {
    rel: 'apple-touch-icon',
    sizes: '256x256',
    href: appleTouchIcon,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: favicon32x32,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '194x194',
    href: favicon194x194,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '192x192',
    href: androidChrome192x192,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: favicon16x16,
  },
  {
    rel: 'manifest',
    href: webmanifest,
  },
  {
    rel: 'mask-icon',
    href: safariPinnedTab,
    color: '#322DA0',
  },
];

export default favicon;
